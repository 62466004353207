<template>
	<section class="container">
		<div class="row">
			<div class="col-12">
				<h1>Ny adgangskode</h1>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<b-form @submit.prevent="onSubmit">
					<b-form-group id="login">
						<div class="row">
							<div class="col-lg-6 col-12">
								<label> Email </label>
								<InputText v-model="email" :required="true" />
							</div>
						</div>
						<br />
						<BaseActionButton v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="login-button"> Få ny adgangskode </BaseActionButton>
					</b-form-group>
				</b-form>
			</div>
		</div>
	</section>
</template>
<script>
import firebaseApp from '@/plugins/firebase'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import InputText from '@/modules/global/atomComponents/InputText.vue'

export default {
	name: 'Login',
	components: {
		InputText,
	},
	data() {
		return {
			clicked: false,
			email: '',
		}
	},
	created() {
		this.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			try {
				var auth = await getAuth(firebaseApp)
				this.clicked = true
				var email = this.email
				await sendPasswordResetEmail(auth, email)
				this.clicked = false
				this.toast('SUCCESS', 'Du vil nu modtage en email, hvor du kan nulstille din adgangskode', true)
				this.$router.push('home')
			} catch (error) {
				console.log(error)
				this.clicked = false
				this.toast('ERROR', 'Noget gik galt - Email findes ikke i systemet', false)
			}
		},
	},
}
</script>
<style lang="scss"></style>
